.App {
  text-align: center;
 
  padding-top: 20px;
  color: white;
  font-family: "Rajdhani", sans-serif;

  
}
body{
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    from(#00e4ff),
    color-stop(#00eef2),
    color-stop(#00f6dc),
    color-stop(#15fbbc),
    to(#69ff97)
  );
  background: linear-gradient(
    to bottom,
    #00e4ff,
    #00eef2,
    #00f6dc,
    #15fbbc,
    #69ff97
  );
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
  overflow: hidden;
  font-size: 30px;
}
td{
  padding: 25px;
}
td{
  border-bottom: 1px solid white;
  
}
tr{
  border-radius: 50%;
  
}
table{
  border: none;
}
.login{
  background-color:#0D4F74 ;
  width: 300px;
  margin: auto;
  padding: 20px;
  
  font-family: "Rajdhani", sans-serif;
  border-radius: 2px;
}
h4{
  font-family: "Rajdhani", sans-serif;
}
.login-form{
  width: 200px;
  margin-top: 20px;
  border: none;
  background: #d9d9d9;
  color: #000;
  font-family: "Rajdhani", "sans-serif";
  font-size: 20px;
  font-style: normal;
  font-weight: bold;
  padding: 10px 20px 10px 20px;
  border: none;
  outline: none;
}

.login-btn{
  color: #fff;

  width: 120px;
  height: 50px;
  margin-top: 10px;
  background-color: #0E1B23;

  font-size: 15px;
  font-style: normal;

  font-weight: bolder;
  border: 0;
  z-index: 999;
  outline: 0;
  -webkit-user-select: none;
  touch-action: manipulation;
  user-select: none;
  -webkit-user-select: none;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
